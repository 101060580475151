'use strict';
import 'firebase/auth';
import firebaseui from 'firebaseui';
import 'firebaseui/dist/firebaseui.css';

class LoginComponent extends HTMLElement {
    constructor(firebase) {
        super();

        this.firebase = firebase;
        this._signin = document.createElement('div');
        this._signin.classList.add('signin');
        document.body.appendChild(this._signin);

        this._signin.innerHTML = `
            <div id="firebaseui-auth-container"></div>
            <div id="loader">Loading...</div>
            <div id="error">Error occurred</div>
        `;
    }

    // noinspection JSUnusedGlobalSymbols
    connectedCallback() {
        this.login();
    }

    login() {
        // noinspection JSUnusedGlobalSymbols
        const firebaseUiConfig = {
            callbacks: {
                signInSuccessWithAuthResult: function () {
                    return false;
                },
                signInFailure: function (error) {
                    // Some unrecoverable error occurred during sign-in.
                    // Return a promise when error handling is completed and FirebaseUI
                    // will reset, clearing any UI. This commonly occurs for error code
                    // 'firebaseui/anonymous-upgrade-merge-conflict' when merge conflict
                    // occurs. Check below for more details on this.
                    const errorElem = document.getElementById('error');
                    errorElem.style.display = 'block';
                    errorElem.textContent = error.message;
                    return Promise.resolve();
                },
                uiShown: function () {
                    // The widget is rendered.
                    // Hide the loader.
                    document.getElementById('loader').style.display = 'none';
                }
            },
            credentialHelper: firebaseui.auth.CredentialHelper.ACCOUNT_CHOOSER_COM,
            signInFlow: 'popup',
            signInOptions: [
                this.firebase.auth.GoogleAuthProvider.PROVIDER_ID
            ],
            tosUrl: ''
        };

        this.firebase.auth().onAuthStateChanged(user => {
            if (user) {
                // User is signed in.
                this._signin.style.display = 'none';

                this.onLogin();
            } else {
                // User is signed out.
                this._signin.style.display = 'block';

                const ui = new firebaseui.auth.AuthUI(this.firebase.auth());
                // The start method will wait until the DOM is loaded.
                ui.start('#firebaseui-auth-container', firebaseUiConfig);
            }
        }, function (error) {
            console.log(error);
        });
    }

    setLoginListener(callback) {
        this.loginListener = callback;
    }

    onLogin() {
        this.loginListener();
    }
}

customElements.define('login-component', LoginComponent);
export default LoginComponent;
