'use strict';
import firebase from 'firebase/app';
import 'material-design-lite/material.min.css';
import 'material-design-lite/material.min';

import './main.css';
import LoginComponent from './LoginComponent';
import Portfolio from './Portfolio';

class RootElement extends HTMLElement {
    constructor() {
        super();

        const config = {
            apiKey: "AIzaSyBLoEydO1rUbRkPR2fBzdSrN50rBPVhPpA",
            authDomain: "crypto.olegkalugin.com",
            databaseURL: "https://tradebot-29863.firebaseio.com",
            projectId: "tradebot-29863",
            storageBucket: "tradebot-29863.appspot.com",
            messagingSenderId: "645306275711"
        };
        firebase.initializeApp(config);

        const mainHeader = document.createElement('h2');
        mainHeader.textContent = 'Portfolio';
        mainHeader.classList.add('center', 'header');
        document.body.appendChild(mainHeader);

        const loginComponent = new LoginComponent(firebase);
        document.body.appendChild(loginComponent);

        this.portfolio = new Portfolio(firebase);
        document.body.appendChild(this.portfolio);

        loginComponent.setLoginListener(() => this.portfolio.loadData());
    }
}

customElements.define('porfolio-app', RootElement);
document.body.appendChild(new RootElement());
