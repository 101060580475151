'use strict';
import 'firebase/firestore';
import moment from 'moment';
import Chart from 'chart.js';
import _ from 'lodash';

import refreshSvg from 'material-design-icons/navigation/svg/production/ic_refresh_48px.svg';

class Portfolio extends HTMLElement {
    constructor(firebase) {
        super();
        this._chartDays = 7;

        this.db = firebase.firestore();
        this.db.settings({timestampsInSnapshots: true});

        this._btcBalance = 0;
        this._btcUsdPrice = 0;
        this._ethBalance = 0;
        this._ethUsdPrice = 0;
        this._profit = 0;
        this._bnbDifference = 0;

        const rootElem = document.createElement('div');
        rootElem.classList.add('content');
        document.body.appendChild(rootElem);

        this._refreshButton = document.createElement('button');
        this._refreshButton.setAttribute('type', 'button');
        this._refreshButton.classList.add('refresh-button');
        rootElem.appendChild(this._refreshButton);

        this._refreshButton.addEventListener('click', () => this.loadData());

        this._refreshImage = document.createElement('img');
        this._refreshImage.setAttribute('src', refreshSvg);
        this._refreshImage.classList.add('svg-container');
        this._refreshButton.appendChild(this._refreshImage);

        const balance = document.createElement('div');
        balance.classList.add('balance');
        rootElem.appendChild(balance);

        const balanceHeader = document.createElement('h4');
        balanceHeader.classList.add('subheader');
        balanceHeader.innerText = 'Balance';
        balance.appendChild(balanceHeader);

        this._btcBalanceElem = document.createElement('p');
        balance.appendChild(this._btcBalanceElem);
        this.updateBtcDisplay();

        this._ethBalanceElem = document.createElement('p');
        balance.appendChild(this._ethBalanceElem);
        this.updateEthDisplay();

        this._totalElem = document.createElement('p');
        balance.appendChild(this._totalElem);
        this.updateTotalDisplay();

        const profitContainer = document.createElement('div');
        profitContainer.classList.add('profit');
        rootElem.appendChild(profitContainer);

        const profitHeader = document.createElement('h4');
        profitHeader.classList.add('subheader');
        profitHeader.innerText = 'Profit';
        profitContainer.appendChild(profitHeader);

        this._profitElem = document.createElement('p');
        profitContainer.appendChild(this._profitElem);
        this.updateProfit();

        this.chart = this.createChart(rootElem);
    }

    setBtcBalance(btcBalance) {
        this._btcBalance = btcBalance;
        this.updateBtcDisplay();
        this.updateTotalDisplay();
    }

    setBtcUsdPrice(btcUsdPrice) {
        this._btcUsdPrice = btcUsdPrice;
        this.updateBtcDisplay();
        this.updateTotalDisplay();
        this.updateProfit();
    }

    updateBtcDisplay() {
        this._btcBalanceElem.innerText =
            `BTC: ${this._btcBalance.toFixed(8)} - $${(this._btcBalance * this._btcUsdPrice).toFixed(2)}`;
    }

    setEthBalance(ethBalance) {
        this._ethBalance = ethBalance;
        this.updateEthDisplay();
        this.updateTotalDisplay();
    }

    setEthUsdPrice(ethUsdPrice) {
        this._ethUsdPrice = ethUsdPrice;
        this.updateEthDisplay();
        this.updateTotalDisplay();
        this.updateProfit();
    }

    updateEthDisplay() {
        this._ethBalanceElem.innerText =
            `ETH: ${this._ethBalance.toFixed(8)} - $${(this._ethBalance * this._ethUsdPrice).toFixed(2)}`;
    }

    updateTotalDisplay() {
        this._totalElem.innerText =
            `Total: $${(this._btcBalance * this._btcUsdPrice + this._ethBalance * this._ethUsdPrice).toFixed(2)}`;
    }

    updateProfit(bnbDifference) {
        if (this.chart && this.chart.data.datasets[0].data.length) {
            const btcProfit = _.last(this.chart.data.datasets[0].data) - this.chart.data.datasets[0].data[0];
            const usdBtcProfit = btcProfit * this._btcUsdPrice;

            const ethProfit = _.last(this.chart.data.datasets[1].data) - this.chart.data.datasets[1].data[0];
            const usdEthProfit = ethProfit * this._ethUsdPrice;

            if (bnbDifference) {
                this._bnbDifference = bnbDifference;
            }
            const bnbUsdProfit = this._bnbDifference * this._bnbUsdPrice;

            this._profit = usdBtcProfit + usdEthProfit + bnbUsdProfit;
        }
        this._profitElem.innerText = `$${this._profit.toFixed(2)}`;
    }

    async loadData() {
        this.setLoading(true);

        await Promise.all([this.updateChart(), this.updateBalance()]);

        this.setLoading(false);
    }

    async getTrade(timestamp) {
        let querySnapshot = await this.db.collection('trades')
            .where('timestamp', '<', timestamp)
            .orderBy('timestamp', 'desc').limit(1)
            .get();

        if (querySnapshot.empty) {
            querySnapshot = await this.db.collection('trades')
                .orderBy('timestamp', 'asc').limit(1)
                .get();
        }

        return querySnapshot.docs[0].data();
    }

    async updateBalance() {
        let querySnapshot = await this.db.collection('trades')
            .orderBy('timestamp', 'desc').limit(1)
            .get();
        const lastTrade = querySnapshot.docs[0].data();

        const btcBalance = lastTrade.balance.btc;
        const ethBalance = lastTrade.balance.eth;

        this.setBtcBalance(btcBalance);
        this.setEthBalance(ethBalance);

        const [btcPrice, ethPrice, bnbPrice] = await Portfolio.getPrices();

        this._bnbUsdPrice = bnbPrice;
        this.setBtcUsdPrice(btcPrice);
        this.setEthUsdPrice(ethPrice);
    }

    async setChartDays(days, activeButton) {
        this._chartDays = days;
        this.markButtonActive(activeButton);

        this.setLoading(true);
        await this.updateChart();
        this.setLoading(false);
    }

    setLoading(isLoading) {
        if (isLoading) {
            this._refreshImage.classList.add('rotate');
        } else {
            this._refreshImage.classList.remove('rotate');
        }
    }

    markButtonActive(button) {
        const pressedColor = 'mdl-color--teal';

        this._buttonSevenDays.classList.remove(pressedColor);
        this._buttonThirtyDays.classList.remove(pressedColor);
        this._buttonThreeMonths.classList.remove(pressedColor);

        button.classList.add(pressedColor);
    }

    createChart(container) {
        const chartContainer = document.createElement('div');
        chartContainer.classList.add('chart-container');
        chartContainer.innerHTML = '<h4 class="subheader center">Trades</h4>';
        container.appendChild(chartContainer);

        const chartControls = document.createElement('div');
        chartContainer.appendChild(chartControls);

        this._buttonSevenDays = document.createElement('button');
        this._buttonSevenDays.setAttribute('type', 'button');
        this._buttonSevenDays.innerHTML = '<span class="mdl-chip__text">7 Days</span>';
        this._buttonSevenDays.classList.add('mdl-chip');
        this._buttonSevenDays.addEventListener('click', () => this.setChartDays(7, this._buttonSevenDays));
        chartControls.appendChild(this._buttonSevenDays);

        this._buttonThirtyDays = document.createElement('button');
        this._buttonThirtyDays.setAttribute('type', 'button');
        this._buttonThirtyDays.innerHTML = '<span class="mdl-chip__text">30 Days</span>';
        this._buttonThirtyDays.classList.add('mdl-chip');
        this._buttonThirtyDays.addEventListener('click', () => this.setChartDays(30, this._buttonThirtyDays));
        chartControls.appendChild(this._buttonThirtyDays);

        this._buttonThreeMonths = document.createElement('button');
        this._buttonThreeMonths.setAttribute('type', 'button');
        this._buttonThreeMonths.innerHTML = '<span class="mdl-chip__text">3 Months</span>';
        this._buttonThreeMonths.classList.add('mdl-chip');
        this._buttonThreeMonths.addEventListener('click', () => this.setChartDays(92, this._buttonThreeMonths));
        chartControls.appendChild(this._buttonThreeMonths);

        this.markButtonActive(this._buttonSevenDays);

        const chartElem = document.createElement('canvas');
        chartElem.classList.add('chart');
        chartContainer.appendChild(chartElem);

        const ctx = chartElem.getContext('2d');
        return new Chart(ctx, {
            type: 'line',
            data: {
                labels: [],
                datasets: [
                    {
                        label: 'BTC',
                        data: []
                    },
                    {
                        label: 'ETH',
                        data: [],
                        hidden: true
                    }
                ]
            },
            options: {
                responsive: true,
                responsiveAnimationDuration: 250,
                maintainAspectRatio: false,
                elements: {
                    line: {
                        borderColor: 'rgba(0, 128, 128, 0.5)',
                        backgroundColor: 'rgba(0, 128, 128, 0.1)',
                        tension: 0.2
                    },
                    point: {
                        borderColor: 'rgba(0, 128, 128, 0.5)',
                        backgroundColor: 'rgba(0, 128, 128, 0.5)'
                    }
                },
                legend: {
                    display: true,
                }
            }
        });
    }

    async updateChart() {
        const now = moment();
        const today = moment(now);
        today.startOf('day');

        const chartLabels = this.chart.data.labels;
        if (chartLabels.length &&
            today.format('MM/DD') === chartLabels[chartLabels.length - 2] &&
            moment(today).subtract(this._chartDays, 'days').format('MM/DD') === chartLabels[0]) {

            await this._updateChartWithCurrentTime(now);
            this.chart.update();
            return;
        }

        const labels = [];
        const promises = [];

        let step = Math.trunc(this._chartDays / 10) || 1;
        for (let i = this._chartDays; i >= 0; i = i - step) {
            const past = moment(today).subtract(i, 'days');
            labels.push(past.format('MM/DD'));
            promises.push(this.getTrade(past.valueOf()));
        }

        labels.push([now.format('MM/DD'), now.format('HH:mm')]);
        promises.push(this.getTrade(now.valueOf()));

        const data = await Promise.all(promises);

        this.chart.data.labels = labels;
        this.chart.data.datasets[0].data = data.map(trade => trade.balance.btc);
        this.chart.data.datasets[1].data = data.map(trade => trade.balance.eth);

        this.chart.update();

        const bnbDifference = _.last(data).balance.bnb - data[0].balance.bnb;
        this.updateProfit(bnbDifference);
    }

    async _updateChartWithCurrentTime(now) {
        const trade = await this.getTrade(now.valueOf());

        this.chart.data.labels[this.chart.data.labels.length - 1] = [now.format('MM/DD'), now.format('HH:mm')];
        this.chart.data.datasets[0].data[this.chart.data.datasets[0].data.length - 1] = trade.balance.btc;
        this.chart.data.datasets[1].data[this.chart.data.datasets[1].data.length - 1] = trade.balance.eth;
    }

    static async getPrices() {
        try {
            const response = await fetch('https://api.coinranking.com/v1/public/coins?symbols=BTC,ETH,BNB&base=USD');
            if (response.status !== 200) {
                console.log('Looks like there was a problem.', response.status, response.statusText, response.type);
                return [0, 0];
            }

            const responseData = await response.json();
            const btcData = _.find(responseData.data.coins, coin => coin.symbol.toLowerCase() === 'btc');
            const ethData = _.find(responseData.data.coins, coin => coin.symbol.toLowerCase() === 'eth');
            const bnbData = _.find(responseData.data.coins, coin => coin.symbol.toLowerCase() === 'bnb');
            return [parseFloat(btcData.price), parseFloat(ethData.price), parseFloat(bnbData.price)];
        } catch (err) {
            console.error(err);
            return [0, 0];
        }
    }
}

customElements.define('portfolio-container', Portfolio);
export default Portfolio;
